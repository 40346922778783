import React, { ReactNode, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import tw, { styled } from "twin.macro"

import ConditionalWrapper from "@components/ConditionalWrapper"
import { Case, Default, Switch, When } from "@components/If"
import InfiniteScroll from "@components/InfiniteScroll"
import Skeleton from "@components/Skeleton"
import CustomScroll from "@components/v3/CustomScroll"
import EmptyState from "@components/v3/EmptyState"
import Icons from "@components/v3/Icons"
import Pagination from "@components/v3/Pagination"
import isNull from "@utils/isNull"

type Align = "left" | "center" | "right"

interface WrapperProps {
    width?: number
}

const Wrapper = styled.div<WrapperProps>`
    width: ${({ width }) => `${width}px`};
    min-width: 100%;

    .rcs-custom-scroll .rcs-custom-scrollbar {
        ${tw`!w-1`}
        ${tw`!right-1`}
    }
`

interface StyledTableProps {
    width?: number
}

const StyledTable = styled.table<StyledTableProps>`
    width: ${({ width }) => (width ? `${width}px` : "100%")};
    min-width: 100%;

    border-collapse: collapse;
    * {
        font-family: ${({ theme }) => theme.fontFamily};
        font-size: 16px;
    }
`

interface RowProps {
    noHover?: boolean
    expandedRow?: boolean
    isExpandable?: boolean
    isExpanded?: boolean
    scrollbarWidth?: number
}

const Row = styled.tr<RowProps>`
    ${tw`table w-full table-fixed`}

    ${({ isExpandable }) => isExpandable && tw`cursor-pointer`}
    ${({ isExpanded }) => !isExpanded && tw`border-b border-b-neutral-200 dark:border-b-background-dark-600`}
    ${({ isExpanded, expandedRow }) =>
        (isExpanded || expandedRow) && tw`bg-background-strong dark:bg-dark-background-strong`}
    ${({ noHover, expandedRow }) => !noHover && !expandedRow && tw`hover:bg-grey-25 dark:hover:bg-dark-grey-25`}
    width: ${({ scrollbarWidth }) => (scrollbarWidth ? `calc(100% - ${scrollbarWidth}px)` : "100%")};

    &:last-child {
        ${tw`border-transparent dark:border-transparent`}
    }
`

const Header = styled.thead`
    ${Row} {
        ${tw`hover:bg-transparent! dark:hover:bg-transparent!`}
    }
`

interface HeadingProps {
    width?: number
    align?: Align
}

const Heading = styled.th<HeadingProps>`
    max-width: ${({ width }) => width}px;
    text-align: ${({ align }) => align};
    font-weight: inherit;
    user-select: none;
    padding: 10px 15px;
`

Heading.defaultProps = {
    width: 0,
    align: "left"
}

interface HeadingSortWrapperProps {
    align?: Align
}

const HeadingSortWrapper = styled.div<HeadingSortWrapperProps>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${({ align }) => {
        switch (align) {
            case "left":
                return "flex-start"
            case "center":
                return "center"
            case "right":
                return "flex-end"
            default:
                return "flex-start"
        }
    }};
`

HeadingSortWrapper.defaultProps = {
    align: "left"
}

const HeadingSort = styled.span<{ active?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 4px 8px;
    cursor: pointer;

    ${({ active }) =>
        active &&
        tw`bg-foreground-teal dark:bg-dark-foreground-teal border-focus dark:border-dark-focus text-primary dark:text-dark-primary rounded-sm`}
`

const Body = styled.tbody`
    display: block;
`

const EmptyWrapper = styled.td``

interface DataProps {
    align?: Align
}

const Data = styled.td<DataProps>`
    align-items: center;
    text-align: ${({ align }) => align || "left"};
    padding: 10px 15px;
`

interface TableData {
    /**
     * Key or index must same with data index at columns
     */
    [field: string]: any
}

export interface TableColumn {
    /**
     * Display field of the data record
     */
    dataIndex: string
    /**
     * Title of this column
     */
    title: string
    /**
     * Width of this column
     */
    width?: number
    /**
     * The specify which way that column is aligned
     */
    align?: Align
    /**
     * Enable/disable sort of this column, default is true (enable)
     */
    sort?: boolean
    /**
     * Renderer of the table cell
     */
    render?: (value: string | number, data: TableData, isExpanded: boolean) => React.ReactNode
    /**
     * onclick header function
     */
    onHeaderClick?: () => void
    /**
     * set header classname
     */
    headClassName?: string
}

interface TableInfiniteScroll {
    /**
     * it tells the InfiniteScroll component on whether to call next function on reaching the bottom and shows an endMessage to the user
     */
    hasMore: boolean
    /**
     * A threshold value defining when InfiniteScroll will call next. Default value is 0.8. It means the next will be called when user comes below 80% of the total height.
     */
    scrollThreshold?: number
    /**
     * a function which must be called after reaching the bottom. It must trigger some sort of action which fetches the next data.
     */
    next: () => void
}

interface Expandable {
    /**
     * Enable row can be expandable
     */
    rowExpandable: (record: TableData) => boolean
    /**
     * Expanded container render for each row
     */
    expandedRowRender: (record: TableData, expanded: boolean) => ReactNode
}

interface Scroll {
    /**
     * Set horizontal scrolling, can also be used to specify the width of the scroll area in pixel
     */
    x: number
}

interface OnRow {
    onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
}

interface TableProps {
    /**
     * Columns of table
     */
    columns: TableColumn[]
    /**
     * Data record array to be displayed, key or index must same with data index at columns
     */
    data?: TableData[]
    /**
     * Set table loading
     */
    loading?: boolean
    /**
     * Set table loader component per data index (column)
     */
    loader?: TableData
    /**
     * Set table loading row count
     */
    loadingRowCount?: number
    /**
     * Set props on per row
     */
    onRow?: (record: TableData) => OnRow
    /**
     * Sort function for custom sort, see Array.sort's compareFunction.
     */
    onSort?: (a: TableData, b: TableData, defaultSortFunc: () => number) => number
    /**
     * Props for infinite scroll
     */
    infiniteScroll?: TableInfiniteScroll
    /**
     * className
     */
    className?: string
    /**
     * Wrapper className
     */
    wrapperClassName?: string
    /**
     * Header className
     */
    headerClassName?: string
    /**
     * Row's className
     */
    rowClassName?: (record: TableData) => string
    /**
     * Body className
     */
    bodyClassName?: string
    /**
     * Config expandable content
     */
    expandable?: Expandable
    /**
     * Whether the table can be scrollable
     */
    scroll?: Scroll
    /**
     * Set max row to be shown
     */
    maxRow?: number
    /**
     * Set text when empty data
     */
    emptyMessage?: string | React.ReactNode
    /**
     * Set subtitle when empty data
     */
    emptySubtitle?: string | React.ReactNode
    /**
     * Using custom scroll
     */
    customScroll?: boolean
    /*
     * Set default sort data index
     */
    defaultSortDataIndex?: string
    /**
     * Set default sort type
     */
    defaultSortType?: SortType
    /**
     * Set scrolable target id
     */
    scrollableTarget?: string
    /**
     * Set nohover row
     */
    noHover?: boolean
    /**
     * With sort on header
     */
    withSort?: boolean
    /**
     * page of pagination
     */
    currentPage?: number
    /**
     * Page change callback
     * @param page The page number.
     * @returns void
     * @default () => {}
     */
    onPageChange?: (page: number) => void
    /**
     * total of pages
     */
    totalPages?: number
    /**
     * With pagination
     */
    withPagination?: boolean
}

const Table: React.FC<TableProps> = ({
    columns,
    data,
    loading: loadingProp,
    loader: loaderProp,
    loadingRowCount,
    onRow,
    onSort,
    infiniteScroll,
    className: classNameProp,
    wrapperClassName,
    headerClassName,
    rowClassName,
    bodyClassName,
    expandable,
    scroll,
    maxRow,
    emptyMessage,
    emptySubtitle,
    customScroll,
    defaultSortDataIndex,
    defaultSortType,
    scrollableTarget,
    noHover,
    withSort,
    currentPage,
    onPageChange,
    totalPages,
    withPagination
}: TableProps) => {
    const [loading, setLoading] = useState(loadingProp)
    const [sortDataIndex, setSortDataIndex] = useState<string | undefined>(defaultSortDataIndex)
    const [sortType, setSortType] = useState<SortType | undefined>(defaultSortType)
    const [expandedIndex, setExpandedIndex] = useState<number | undefined>(undefined)
    const [scrollbarWidth, setScrollbarWidth] = useState<number>(0)

    const bodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>

    const getScrollbarWidth = () => {
        const newWidth = bodyRef.current.offsetWidth - bodyRef.current.clientWidth
        setScrollbarWidth(newWidth)
    }

    const handlePageChange = (page: number) => {
        onPageChange?.(page)
    }

    useEffect(() => {
        getScrollbarWidth()

        window.addEventListener("resize", getScrollbarWidth)
        return () => {
            window.removeEventListener("resize", getScrollbarWidth)
        }
    }, [])

    useEffect(() => {
        setLoading(loadingProp)
    }, [loadingProp])

    useEffect(() => {
        const isEmptyData = !data || data.length === 0
        const isExpandedIndex = typeof expandedIndex !== "undefined"

        if (isEmptyData && isExpandedIndex) {
            setExpandedIndex(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const handleExpandRow = (index: number) => {
        if (expandedIndex === index) {
            setExpandedIndex(undefined)
            return
        }

        setExpandedIndex(index)
    }

    const handleNext = async () => {
        setLoading(true)
        await infiniteScroll?.next()
        setLoading(false)
    }

    const handleSetSort = (dataIndex: string) => {
        if (sortDataIndex === dataIndex) {
            switch (sortType) {
                case "asc":
                    setSortDataIndex(sortDataIndex)
                    setSortType("desc")
                    break
                case "desc":
                    setSortDataIndex(undefined)
                    setSortType(undefined)
                    break
                default:
                    setSortDataIndex(sortDataIndex)
                    setSortType("asc")
            }

            return
        }

        setSortDataIndex(dataIndex)
        setSortType("asc")
    }

    const renderedHeaders = columns.map(
        ({ dataIndex, width, align = "left", title, sort = withSort, onHeaderClick, headClassName }) => {
            const sortStatus = sortDataIndex === dataIndex ? sortType : undefined
            const onSortActive = sortStatus === "asc" || sortStatus === "desc"

            return (
                <Heading key={dataIndex} align={align} width={width} onClick={onHeaderClick} className={headClassName}>
                    {sort ? (
                        <HeadingSortWrapper align={align}>
                            <HeadingSort active={onSortActive} onClick={() => handleSetSort(dataIndex)}>
                                {title}
                                <Switch>
                                    <Case condition={sortStatus === "asc"}>
                                        <Icons icon='ChevronUp' width={18} height={18} />
                                    </Case>
                                    <Case condition={sortStatus === "desc"}>
                                        <Icons icon='ChevronDown' width={18} height={18} />
                                    </Case>
                                    <Default>
                                        <Icons icon='Sort' width={18} height={18} />
                                    </Default>
                                </Switch>
                            </HeadingSort>
                        </HeadingSortWrapper>
                    ) : (
                        title
                    )}
                </Heading>
            )
        }
    )

    const renderedBody = () => {
        let sortedData = data ? [...data] : []

        const emptyData = sortedData.length === 0

        const loader = Array.from({ length: loadingRowCount || 10 }).map((_, index) => {
            const renderedDataByColumn = columns.map(({ dataIndex, width, align }) => (
                <Data key={dataIndex} width={width} align={align}>
                    {loaderProp?.[dataIndex] ?? <Skeleton width={width ? width / 2 : 100} />}
                </Data>
            ))

            const className = rowClassName ? rowClassName({}) : ""

            return (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={index} className={className}>
                    {renderedDataByColumn}
                </Row>
            )
        })

        if (!data && emptyData) {
            return loader
        }

        if (emptyData) {
            return (
                <Row noHover>
                    <EmptyWrapper colSpan={100}>
                        <EmptyState
                            className='pt-2'
                            title={emptyMessage}
                            description={emptySubtitle}
                            illustration='NoList'
                        />
                    </EmptyWrapper>
                </Row>
            )
        }

        if (sortDataIndex && sortType) {
            sortedData = sortedData.sort((a, b) => {
                const prevData = a?.[sortDataIndex]
                const nextData = b?.[sortDataIndex]

                if (isNull(prevData) || isNull(!nextData)) {
                    return 0
                }

                const defaultSortFunc = () => {
                    if (typeof prevData === "string" && typeof nextData === "string") {
                        if (sortType === "asc") {
                            return prevData.localeCompare(nextData)
                        }

                        return nextData.localeCompare(prevData)
                    }

                    if (typeof prevData === "number" && typeof nextData === "number") {
                        if (sortType === "asc") {
                            return prevData - nextData
                        }

                        return nextData - prevData
                    }

                    return 0
                }

                return onSort ? onSort(a, b, defaultSortFunc) : defaultSortFunc()
            })
        }

        if (maxRow) {
            sortedData = sortedData.slice(0, maxRow)
        }

        const renderedData = sortedData.map((item, index) => {
            const key = item?.key ?? index
            const isExpandable = expandable?.rowExpandable(item) ?? false
            const isExpanded = key === expandedIndex

            const className = rowClassName ? rowClassName(item) : ""

            const renderedDataByColumn = columns.map(({ dataIndex, width, align, render }) => {
                const value = item?.[dataIndex]

                return (
                    <Data
                        key={dataIndex}
                        width={width}
                        align={align}
                        className={classNames(className, {
                            expand: isExpanded
                        })}
                    >
                        {render?.(value, item || {}, isExpanded) || value}
                    </Data>
                )
            })

            const rowProps = onRow?.(item)

            const handleClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
                rowProps?.onClick?.(event)

                if (isExpandable) {
                    handleExpandRow(key)
                }
            }

            return (
                <React.Fragment key={key}>
                    <Row
                        noHover={noHover}
                        className={className}
                        onClick={handleClick}
                        isExpandable={isExpandable}
                        isExpanded={isExpanded}
                    >
                        {renderedDataByColumn}
                    </Row>
                    <When condition={isExpandable && isExpanded}>
                        <Row className={className} expandedRow>
                            <Data colSpan={columns.length}>{expandable?.expandedRowRender(item, isExpanded)}</Data>
                        </Row>
                    </When>
                </React.Fragment>
            )
        })

        return (
            <>
                {renderedData}
                {loading && loader}
            </>
        )
    }

    const dataLength = data?.length || 0

    return (
        <Wrapper width={scroll?.x} className={classNames("reku-new", wrapperClassName)}>
            <InfiniteScroll
                hasMore={(dataLength > 0 && infiniteScroll?.hasMore) || false}
                next={handleNext}
                dataLength={dataLength || 0}
                // eslint-disable-next-line react/jsx-no-useless-fragment
                loader={<></>}
                scrollableTarget={scrollableTarget}
            >
                <StyledTable className={classNameProp}>
                    <Header className={headerClassName}>
                        <Row scrollbarWidth={scrollbarWidth}>{renderedHeaders}</Row>
                    </Header>
                    <ConditionalWrapper
                        condition={customScroll ?? false}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        wrapper={(children) => <CustomScroll>{children}</CustomScroll>}
                    >
                        <Body id='reku-table-body' ref={bodyRef} className={bodyClassName}>
                            {renderedBody()}
                        </Body>
                    </ConditionalWrapper>
                </StyledTable>
            </InfiniteScroll>
            {withPagination && (
                <div className='!mx-auto !mt-8 flex justify-center items-center'>
                    <Pagination
                        page={currentPage as number}
                        onPageChange={handlePageChange}
                        pageCount={totalPages as number}
                    />
                </div>
            )}
        </Wrapper>
    )
}

Table.defaultProps = {
    data: undefined,
    loading: false,
    loader: undefined,
    loadingRowCount: 10,
    onRow: undefined,
    onSort: undefined,
    infiniteScroll: undefined,
    className: undefined,
    wrapperClassName: undefined,
    headerClassName: undefined,
    rowClassName: undefined,
    bodyClassName: undefined,
    expandable: undefined,
    scroll: undefined,
    maxRow: undefined,
    emptyMessage: undefined,
    emptySubtitle: undefined,
    customScroll: false,
    defaultSortDataIndex: undefined,
    defaultSortType: undefined,
    scrollableTarget: undefined,
    noHover: false,
    withSort: false,
    currentPage: 1,
    onPageChange: () => {},
    totalPages: undefined,
    withPagination: false
}

export default Table
