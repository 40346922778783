import classNames from "classnames"
import tw from "twin.macro"

import Illustration from "@components/Illustrations"
import { IllustrationProps } from "@components/Illustrations/Illustrations"

const Wrapper = tw.div`
    bg-transparent flex flex-col text-center items-center justify-center 
`
const Title = tw.div`
    title-3 xl:title-2 text-main dark:text-dark-main
    mt-6 mb-2
`

const Desc = tw.div`
    paragraph-3 xl:paragraph-2  text-additional dark:text-dark-additional
`

interface Props {
    /**
     * Title
     */
    title?: string | React.ReactNode
    /**
     * Description
     */
    description?: string | React.ReactNode
    /**
     * EmptyState className
     */
    className?: string
    /**
     * illustration name
     */
    illustration?: IllustrationProps["name"]
    /**
     * illustration size
     */
    illustrationSize?: number
    /**
     * Typography title level
     */
    titleClassName?: string
    /**
     * Typography description level
     */
    descriptionClassName?: string
}

const EmptyState: React.FC<Props> = ({
    title,
    description,
    className,
    illustration,
    illustrationSize,
    titleClassName,
    descriptionClassName
}) => (
    <Wrapper className={classNames("reku-new", className)}>
        {illustration && (
            <div>
                <Illustration name={illustration} width={illustrationSize} height={illustrationSize} />
            </div>
        )}
        {title && <Title className={titleClassName}>{title}</Title>}
        {description && <Desc className={descriptionClassName}>{description}</Desc>}
    </Wrapper>
)

EmptyState.defaultProps = {
    title: undefined,
    description: undefined,
    className: undefined,
    illustration: undefined,
    illustrationSize: 160,
    descriptionClassName: undefined,
    titleClassName: undefined
}

export default EmptyState
